<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <b-row>
        <b-col xl="12" xs="12">
          <b-card title="İş Ortaklarımız" style="background-color: #f1f1f1">
            <swiper
              class="swiper-responsive-breakpoints px-4 py-0"
              :options="swiperOptions"
            >
              <swiper-slide v-for="(imag, index) in productImages" :key="index">
                <b-link>
                  <div class="img-container w-55 mx-auto py-75 text-right">
                    <b-row style="max-height: 250px">
                      <b-col xl="12" xs="12">
                        <b-img
                          style="max-height: 250px"
                          :src="imag.urlPath"
                          fluid
                      /></b-col>
                    </b-row>
                    <b-row class="mt-1 mb-1">
                      <b-col xl="12" xs="12">
                        <b-form-input
                          id="name-input"
                          v-model="imag.description"
                          disabled
                          size="sm"
                        />
                      </b-col>
                    </b-row>
                    <b-row class="mt-1 mb-1">
                      <b-col xl="12" xs="12">
                        <b-form-input
                          id="name-input"
                          v-model="imag.title"
                          disabled
                          size="sm"
                        />
                      </b-col>
                    </b-row>

                    <b-button
                      @click="pictureRemove(imag)"
                      size="sm"
                      variant="danger"
                      pill
                    >
                      <feather-icon icon="TrashIcon" /> Sil
                    </b-button>
                  </div>
                </b-link>
              </swiper-slide>

              <!-- Add Arrows -->
              <div slot="button-next" class="swiper-button-next" />
              <div slot="button-prev" class="swiper-button-prev" />
            </swiper> </b-card
        ></b-col>
      </b-row>
      <b-row>
        <b-col xl="3" xs="3">
          <b-media no-body vertical-align="center">
            <b-media-body>
              <small
                >Gerekli Çözünürlük 1920x1080, Resim Boyutu Max 5mb
                olmalıdır.</small
              >
              <div>
                <b-form-file
                  ref="refInputEl"
                  v-model="featuredImage"
                  accept=".jpg, .png, .jpeg"
                  placeholder="Resim Seç"
                  @input="inputImageRenderer"
                />
              </div>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" xs="3">
          <b-form-group label="Başlık" label-for="name-input">
            <b-form-input id="name-input" v-model="description" size="sm" />
          </b-form-group>
        </b-col>
        <b-col xl="4" xs="4">
          <b-form-group label="Açıklama" label-for="name-input">
            <b-form-input id="name-input" v-model="title" size="sm" />
          </b-form-group>
        </b-col>
        <b-col xl="2" xs="2">
          <b-button class="mt-2" block variant="danger" @click="sendImage()"
            >Ekle</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
  BFormInput,
  BOverlay,
  BModal,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormTextarea,
  BTab,
  BTabs,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BDropdown,
    BImg,
    BFormSpinbutton,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BModal,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BCol,
    BTable,
    BFormFile,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    BTab,
    BTabs,
    Swiper,
    SwiperSlide,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      show: false,
      userData: "",
      customerId: 0,
      description: "",
      title: "",
      productImages: [],
      boxOne: "",
      featuredImage: null,
    };
  },
  computed: {},
  created() {
    var uData = JSON.parse(localStorage.getItem("HPUserData"));
    this.userData = uData;
    this.customerId = this.userData.customerId;
    this.getPicture();
  },

  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    };

    return {
      swiperOptions,
    };
  },
  methods: {
    async getPicture() {
      this.show = true;
      this.$http
        .get("document/gettypeimage?type=" + 2)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.productImages = [];
            this.productImages = satirlar;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },

    async pictureRemove(imag) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Resmi Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxOne = value;
          if (imag.cover == true) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hata`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: "Kapak Resmi Silinemez...",
              },
            });
            return;
          }

          if (value == true) {
            this.show = true;
            this.$http
              .delete(`document?id=${imag.id}`)
              .then((response) => {
                if (response.status === 200) {
                  this.productImages = this.productImages.filter(
                    (i) => i.id !== imag.id
                  );
                  this.show = false;

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async inputImageRenderer(data) {
      if (data.size > 5242880) {
        this.featuredImage = null;
        this.$refs.refInputEl.reset();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Yüklemek istediğiniz dosya boyutu çok yüksek..!",
          },
        });
        return;
      }

      this.featuredImage = data;
    },
    async sendImage() {
      if (this.featuredImage == "" || this.featuredImage == null) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Resim Boş Olamaz..!!",
          },
        });
        return;
      }
      if (this.description == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Açıklama Boş Olamaz..!!",
          },
        });
        return;
      }
      if (this.title == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Başlık Boş Olamaz..!!",
          },
        });
        return;
      }
      this.show = true;
      let formData = new FormData();
      formData.append("file", this.featuredImage);

      var kapak = false;

      this.$http
        .post(
          "Document/AddImageType?cover=" +
            kapak +
            "&type=" +
            2 +
            "&title=" +
            this.title +
            "&description=" +
            this.description, // slider 0 product 1 brand 2
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Dosya yükleme için content type'ı belirtilmelidir
            },
          }
        )
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.featuredImage = null;
            this.$refs.refInputEl.reset();
            this.description = "";
            this.title = "";
            this.getPicture();
          }
        })
        .catch((error) => {
          this.show = false;
          this.featuredImage = null;
          this.$refs.refInputEl.reset();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
</style>
<style>
.hover-effect {
  transition: font-size 0.3s, text-shadow 0.3s; /* Geçiş efekti eklemek için */
}

.hover-effect:hover {
  font-size: 18px; /* Font boyutunu büyüt (örneğin) */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Metne gölge ekle (örneğin) */
}
.image-container {
  height: 200px; /* Resmin sabit yüksekliği */
  overflow: hidden; /* İçeriğin taşmasını engeller */
  transform-origin: top;
}

/* Küçülme animasyonunu tanımlayan CSS */
.shrink-on-hover {
  transition: transform 0.5s;
}

.shrink-on-hover:hover {
  transform: scale(0.5);
  transform-origin: top;
}
</style>
